import React from 'react';
import './Header.css';
import { Link, useLocation } from 'react-router-dom';
import { baseRoutes } from '../navigation/AllRoutes';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { Icon } from '@mui/material';
import SideMenu from './Sidemenu';
import { saveLanguagePreference } from '../utils/LanguageStorage';

const Header: React.FC = () => {
    let location = useLocation();
    const { t, i18n: { changeLanguage, language: currLang } } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    // const handleLanguageChange = (lang: string) => {
    //     changeLanguage(lang);
    // }
    const handleMenuClose = () => {
        setIsMenuOpen(false);
    }

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    }

    const isSelected = (route: string) => {
        if (location.pathname.includes(route) && route !== "/") return true;
        else if (location.pathname === route) return true;
    }

    const isMusic = () => {
        return location.pathname.startsWith('/music') ? true : false;
    }

    const handleLanguageChange = (lang: string) => {
        changeLanguage(lang);
        saveLanguagePreference(lang);
        handleMenuClose();
    }

    return (
        <header className={isMusic() ? 'music' : ''}>
            <SideMenu isOpen={isMenuOpen} onClose={handleMenuClose}>
                <nav className="sidemenu-links">
                    <ul>
                        {baseRoutes.map((route) => (
                            !route.path.startsWith("/music") && <li key={route.path} className={isSelected(route.path) ? 'selected' : ''}>
                                <Link to={route.path} onClick={handleMenuClose}>{t(`navigation.${route.name.toLowerCase()}`)}</Link>
                            </li>
                        ))}
                    </ul>

                </nav>
                <div className="lang-switcher">
                    <button onClick={() => handleLanguageChange('en')} disabled={currLang === 'en'}>English</button>
                    <button onClick={() => handleLanguageChange('fi')} disabled={currLang === 'fi'}>Suomi</button>
                </div>
            </SideMenu>

            <div className="inner-header flex">
                <Link to="/">
                    <div className="h1">Absum<div className="h2">Corporation</div></div>
                </Link>
                <nav>
                    <ul>
                        {baseRoutes.map((route) => (
                            !route.path.startsWith("/music") && <li key={route.path} className={isSelected(route.path) ? 'selected' : ''}>
                                <Link to={route.path}>{t(`navigation.${route.name.toLowerCase()}`)}</Link>
                            </li>
                        ))}
                        {currLang === 'en' && <li><button onClick={() => handleLanguageChange('fi')}>Suomeksi</button></li>}
                        {currLang === 'fi' && <li><button onClick={() => handleLanguageChange('en')}>English</button></li>}
                    </ul>
                </nav>
                
                <div className="sidenav-button">
                    <Icon className='menuicon' component={MenuIcon} onClick={handleMenuOpen} />
                </div>
                {/* <div className="lang-switcher">
                <button onClick={() => handleLanguageChange('en')} disabled={currLang === 'en'}>EN</button>
                <button onClick={() => handleLanguageChange('fi')} disabled={currLang === 'fi'}>FI</button>
            </div> */}
            </div>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
            </svg>
        </header>

    );
};

export default Header;