import React from 'react';
import './Footer.css';
import { Link, useLocation } from 'react-router-dom';
import { baseRoutes } from '../navigation/AllRoutes';
import { useTranslation } from 'react-i18next';
// import ChatBot from '../components/ChatBot';
import { saveLanguagePreference } from '../utils/LanguageStorage';


const Footer: React.FC = () => {
    let location = useLocation();
    const { t, i18n: { changeLanguage, language: currLang } } = useTranslation();
    const handleLanguageChange = (lang: string) => {
        changeLanguage(lang);
        saveLanguagePreference(lang);
    }
    const isSelected = (route: string) => {
        if (location.pathname.includes(route) && route !== "/") return true;
        else if (location.pathname === route) return true;
    }
    return (
        <>
        <svg className="footer-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(48, 90, 115,0.3" />
                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(48, 90, 115,0.5)" />
                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(48, 90, 115,0.7)" />
                <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgb(48, 90, 115)" />
            </g>
            </svg>
        
        <footer>
            <div className="sections">
                <div className="section section-1">
                    <div className="lang-switcher">
                        <button onClick={() => handleLanguageChange('en')} disabled={currLang === 'en'}>English</button>
                        <button onClick={() => handleLanguageChange('fi')} disabled={currLang === 'fi'}>Suomi</button>
                    </div>
                    <p>
                        <strong>Absum Corporation Oy</strong><br />
                        Espoo, {
                            currLang === 'en' ? 'Finland' : 'Suomi'
                        }<br />
                        {
                            currLang === 'en' ? 'VAT ID: FI34121898' : 'Y-Tunnus: 3412189-8'
                        }
                    </p>
                </div>
                {/* <div className="section section-2">
                    
                </div> */}
                <div className="section section-3">
                     <nav>
                        <ul>
                        {baseRoutes.map((route) => (
                            !route.path.startsWith("/music") && 
                            <li key={route.path} className={isSelected(route.path)  ? 'selected' : ''}>
                                <Link to={route.path}>{t(`navigation.${route.name.toLowerCase()}`)}</Link>
                            </li>
                        ))}
                        </ul>
                    </nav>
                </div>
            </div>
            <p className='copy'>&copy; {new Date().getFullYear()} Absum Corporation Oy. <span className="rights">{t('footer.rights')}.</span></p>
        </footer>
        </>
    );
};

export default Footer;